@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.bg-custom-light {
  background-color: #e7e7e7;
}

.bg-dim {
  background-color: rgba(0, 0, 0, 0.6);
}

.cover {
  object-fit: cover;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-5 {
  opacity: 0.5;
}

.image-vertical-scroller {
  overflow-y: auto;
  max-height: 460px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.image-vertical-scroller::-webkit-scrollbar {
  display: none;
}

.h-scroller {
  position: relative;
  z-index: 2;
  height: 3.75rem;
  overflow-y: hidden;
}

.h-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.h-underline .h-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}
